// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "stylesheets/application";

const images = require.context("../images", true);

import Autocomplete from "@trevoreyre/autocomplete-js";
window.Autocomplete = Autocomplete;

import Rails from "@rails/ujs";
global.Rails = Rails;
window.Rails = Rails;

import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";

import "bootstrap";

import "channels";

import "controllers";

Rails.start();

Turbolinks.start();
Turbolinks.setProgressBarDelay(250);

ActiveStorage.start();

const jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("@nathanvda/cocoon");

document.addEventListener("keyup", (e) => {
  if (e.key !== "/" || e.ctrlKey || e.metaKey) return;
  if (/^(?:input|textarea|select|button)$/i.test(e.target.tagName)) return;

  e.preventDefault();
  document.getElementsByClassName("autocomplete-input")[0].focus();
});

window.toggleCheckboxes = (source) => {
  const checkboxes = document.querySelectorAll('input[type="checkbox"]');

  checkboxes.forEach((checkbox) => {
    if (checkbox != source && checkbox.disabled === false)
      checkbox.checked = source.checked;
  });
};
