import { Controller } from "stimulus";

import Litepicker from "litepicker";

export default class extends Controller {
  connect() {
    new Litepicker({
      element: this.element,
      singleMode: true,
    });
  }
}
