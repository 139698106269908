import { Controller } from "stimulus";

import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["job", "material"];

  connect() {
    this.jobSelect = new TomSelect(this.jobTarget);
    this.jobSelect.on("change", (v) => {
      const materialsOnJob = window.job_and_material_ids[v];

      if (materialsOnJob) {
        materialsOnJob.forEach((materialId) => {
          const material = window.materials.find((m) => m.id == materialId);

          if (material) {
            this.materialSelect.updateOption(materialId, {
              ...material,
              substrateType: "00job",
            });
          }
        });
      } else {
        // reset all options to default
        window.materials.forEach((material) => {
          this.materialSelect.updateOption(material.id, material);
        });
      }
    });
    this.materialSelect = new TomSelect(this.materialTarget, {
      valueField: "id",
      searchField: ["substrateCode", "name"],
      options: window.materials,
      sortField: [{ field: "substrateType" }, { field: "$score" }],
      optgroups: [
        { value: "00job", label: "In Job" },
        { value: "board", label: "Board" },
        { value: "roll", label: "Roll" },
        { value: "adhesive", label: "Adhesive" },
        { value: "laminate", label: "Laminate" },
      ],
      optgroupField: "substrateType",
      render: {
        option: function (data, escape) {
          return `<div><div>${escape(data.name)}</div><div><small>${escape(
            data.substrateCode
          )}</small></div></div>`;
        },
        item: function (data, escape) {
          return `<div>${escape(data.name)}</div>`;
        },
      },
    });
  }

  update(e) {
    console.log(this.materialSelect);
    console.log(e);
  }
}
