import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container", "expandable"];

  toggle(event) {
    event.preventDefault();

    this.expandableTarget.classList.toggle("d-none");
    this.containerTarget.classList.toggle("collapsed");
  }
}
