import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["operation", "amount", "hours"];

  connect() {
    this.update();
  }

  update() {
    let operation = this.operationTarget.value;

    if (operation == "") {
      this.amountTarget.classList.remove("d-none");
      this.hoursTarget.classList.add("d-none");
    } else {
      this.amountTarget.classList.add("d-none");
      this.hoursTarget.classList.remove("d-none");
    }
  }
}
