import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["form"];

  toggle(event) {
    if (event.target.checked) {
      this.formTarget.classList.remove("d-none");
    } else {
      this.formTarget.classList.add("d-none");
    }
  }
}
